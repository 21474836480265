<template>
  <path id="Tracé_561" data-name="Tracé 561" d="M52,63.688v-34.5h2.668V42.241a3.935,3.935,0,0,0,2.666-3.763V27.865c0-4.893-3.443-6.635-6.666-6.635h-8c-4.047,0-6.015,2.548-8.506,5.777-.778,1.008-1.585,2.053-2.5,3.069a1.344,1.344,0,0,1-.774.424L24,31.642v1.53A1.33,1.33,0,0,0,25.333,34.5h7.448l4.942-4.92a1.334,1.334,0,0,1,2.275.94v33.17H37.331V33.721l-3.056,3.042a1.335,1.335,0,0,1-.942.389h-8a4,4,0,0,1-4-3.98V30.517a1.333,1.333,0,0,1,1.116-1.309L29.98,27.96c.729-.841,1.408-1.719,2.064-2.571,2.587-3.35,5.26-6.812,10.622-6.812h8c4.647,0,9.333,2.871,9.333,9.288V38.478a6.559,6.559,0,0,1-5.333,6.507v18.7Zm-6.665,0v-19.9H48v19.9Zm-38.667,0A1.331,1.331,0,0,1,5.332,62.36V37.434L.113,25.749A1.338,1.338,0,0,1,0,25.21V21.23A1.33,1.33,0,0,1,1.332,19.9H24.541a1.336,1.336,0,0,1,1.144.645l2.4,3.98-2.289,1.364-2.008-3.336H2.666v2.372L7.885,36.613A1.307,1.307,0,0,1,8,37.152V61.033H30.665V38.478h2.668V62.36A1.33,1.33,0,0,1,32,63.688H6.665ZM12,37.152a1.332,1.332,0,0,1-1.192-.734L5.474,25.8a1.317,1.317,0,0,1,.058-1.291,1.332,1.332,0,0,1,1.133-.631H21.332a1.339,1.339,0,0,1,1.132.625l.831,1.326-2.263,1.405-.44-.7H8.823l4,7.96H20v2.655Zm22.667-27.5V7.595a8.01,8.01,0,0,1,16,0V9.654a8.01,8.01,0,0,1-16,0Zm2.667-2.059V9.654A5.348,5.348,0,0,0,48,9.654V7.595a5.349,5.349,0,0,0-10.666,0Z" transform="translate(0.5 0.5)" stroke="rgba(0,0,0,0)" stroke-width="1"/>
</template>

<script>
export default {
name: "IconEmargement"
}
</script>

<style scoped>

</style>